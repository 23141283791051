import React from 'react';
import { TextField, Button, Container, Dialog, AppBar, Toolbar, IconButton, Typography, List, ListItem, Slide, ThemeProvider, InputAdornment, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { createMuiTheme } from '@material-ui/core/styles';
import {cyan, orange} from '@material-ui/core/colors';
import {Close as CloseIcon, FindInPage as FindInPageIcon, Bookmark as BookmarkIcon, BookmarkBorder as BookmarkBorderIcon, InfoRounded as InfoIcon, AddCircleOutline as AddIcon, RemoveCircleOutline as RemoveIcon, Print as PrintIcon, Visibility, VisibilityOff} from '@material-ui/icons';
import './App.css';
import API from './API';
import Utils from './Utils';
import queryString from 'query-string'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: cyan[700],
    },
  },
});

export default class App extends React.Component {
  constructor() {
    super()
    this.state = {
      source: "",
      paragraph: [],
      wordInfo: null,
      dialogOpen: false,
      loading: true,
      message: ""
    }
    this.placeholder = "ここで文章を入力して右下のボタンをクリックしてください。";
    this.selectedPointer = [0, 0];
    this.first = true
  }

  init = async() => {
    let ans = await API.checkServerAlive();
    if(ans) {
      this.setState({loading: false});
      this.setState({message: ""});
    } else {
      if(this.first) {
        this.setState({message: "系統啟動中，約30秒，請稍待。在此期間您仍然可以繼續輸入文章。"});
        this.first = false
      }
      this.setState({loading: true});
      setTimeout(() => {
        this.init();
      }, 3000);
    }
  }

  handleChange = (event) => {
    this.setState({source: event.target.value});
  }

  query = async() => {
    this.setState({loading: true});
    let ary = this.state.source.split('\n');
    
    let paragraph = [];
    for(let i = 0; i < ary.length; i++) {
      if(ary[i].length === 0) {
        paragraph.push([]);
      } else {
        let res = await API.query(ary[i]);
        res = res.map(it => {
          let dictionary = it['語彙素'];
          if(it['品詞'][1] === "固有名詞" && Utils.hasKanji(it['基本形'][0])) {
            dictionary = it['基本形'][0];
          }
          if(it['語彙素'].indexOf('-') > 0) {
            dictionary = it['語彙素'].split('-')[0];
          }
          
          it['dictionary'] = dictionary;
          return it;
        })
        paragraph.push(res);
      }
    }
    // console.log(paragraph)
    this.setState({
      loading: false,
      paragraph: paragraph
    });
  }

  componentDidMount() {
    const self = this;
    window.addEventListener('popstate', function(event) {
      // console.log(self.state);
      if(self.state.dialogOpen) {
        self.setState({dialogOpen: false});
      } else {
        window.history.back();
      }
    });
    this.init();
  }

  select = async(i, j) => {
    if(i === undefined || j === undefined ) return;
    this.selectedPointer = [i, j];
    let wordInfo = this.state.paragraph[i][j];
    // console.log(wordInfo);
    this.setState({
      wordInfo: wordInfo,
      dialogOpen: true
    });
    window.history.pushState({ noBackExitsApp: true }, '')
  }

  format = (wordInfo, paragraphIdx, wordIdx) => {
    let clickable = true;
    let cls = clickable ? 'clickable' : '';
    if(wordInfo['bookmark']) {
      cls += ' bookmark'
    }

    return <div key={wordIdx} className={cls} style={{display: 'inline-block'}} onClick={e => {
      if(clickable) {
        // this.select(paragraphIdx, wordIdx);
        window.open(`https://dictionary.goo.ne.jp/srch/jn/${wordInfo['dictionary']}/m1u`)
      }
    }}>
      {this.okuriganToFurigana(wordInfo['okurigana'])}
    </div>
  }

  okuriganToFurigana = (okurigana) => {
    if(!okurigana){
      return [];
    }
    let str = "";
    let furigana = "";
    let isFuriganaPart = false;
    let ary = [];
    for(let i = 0; i < okurigana.length; i++) {
      let c = okurigana[i];
      if(c === '(') {
        isFuriganaPart = true;
      } else if(c === ')') {
        ary.push([str, furigana]);
        furigana = "";
        str = "";
        isFuriganaPart = false;
      } else {
        if(isFuriganaPart) {
          furigana += c;
        } else {
          if(str.length > 0 && !Utils.isHiragana(c) && Utils.isHiragana(str.substr(-1))) {
            ary.push([str, furigana]);
            furigana = "";
            str = c;
          } else {
            str += c;
          }
        }
      }
    }
    ary.push([str, furigana]);
    return ary.map((it, idx) => {
      let str = it[0];
      let furigana = it[1];
      if(!furigana) furigana = '\u00A0';
      return(
        <div key={idx} style={{display: 'inline-block'}}>
          <div className="furigana">{furigana}</div>
          <div className="surface">{str}</div>
        </div>
      )
    });
  }

  render() {
    let paragraphs = [];
    for(let i = 0; i < this.state.paragraph.length; i++) {
      let t = this.state.paragraph[i].map((e, j) => this.format(e, i, j));
      paragraphs.push(t);
    }
    const queryParameter = queryString.parse(window.location.search);
    let isPrintMode = false;
    if(queryParameter['preview']) {
      isPrintMode = true
    }
    
    return (
    <div>
      <Container fixed style={{display: "flex", flexDirection: 'column'}}>
        <ThemeProvider theme={theme}>
          <form noValidate autoComplete="off" style={{marginTop: 24}}>
            <TextField
              placeholder={this.placeholder}
              fullWidth={true}
              label="文章入力"
              multiline={true}
              rows={6}
              variant="outlined"
              value={this.state.source} onChange={this.handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
            <div style={{display:'flex', justifyContent:'flex-end'}}>
              <div style={{position: 'relative'}}>
                <Button 
                  color="primary" 
                  disabled={this.state.loading}
                  onClick={e => this.query()}>
                    振り仮名
                </Button>
                {this.state.loading && <CircularProgress size={24} style={{position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft:-12, color: cyan[700]}}/>}
              </div>
            </div>
          </form>
        </ThemeProvider>
      </Container>

      <PrintContainer fixed style={{display: "flex"}} isPrintMode={isPrintMode} paragraphs={paragraphs}/>

      <ThemeProvider theme={theme}>
        <FullScreenDialog
          wordInfo={this.state.wordInfo}
          dialogOpen={this.state.dialogOpen}
          onClose={() => this.setState({dialogOpen: false})}
          addBookmark={(value) => {
            const wordInfo = this.state.wordInfo;
            wordInfo['bookmark'] = value;
            this.setState({wordInfo: wordInfo});
          }}
          onSave={(okurigana, dictionary) => {
            const wordInfo = this.state.wordInfo;
            
            if(okurigana.length !== 0 && okurigana !== wordInfo['okurigana']){
              let paragraphIdx = this.selectedPointer[0];
              let wordIdx = this.selectedPointer[1];
              let rangeStart = Math.max(wordIdx - 1, 0);
              let rangeEnd = Math.min(wordIdx + 2, this.state.paragraph[paragraphIdx].length);
              API.report("送り仮名修正", okurigana, JSON.stringify(
                this.state.paragraph[paragraphIdx].slice(rangeStart, rangeEnd))
              )
            }
            if(dictionary.length !== 0 && dictionary !== wordInfo['dictionary']){
              let paragraphIdx = this.selectedPointer[0];
              let wordIdx = this.selectedPointer[1];
              let rangeStart = Math.max(wordIdx - 1, 0);
              let rangeEnd = Math.min(wordIdx + 2, this.state.paragraph[paragraphIdx].length);
              API.report("字典修正", dictionary, JSON.stringify(
                this.state.paragraph[paragraphIdx].slice(rangeStart, rangeEnd))
              )
            }

            wordInfo['okurigana'] = okurigana;
            wordInfo['dictionary'] = dictionary;
            this.setState({
              wordInfo: wordInfo,
              paragraph: this.state.paragraph,
              dialogOpen: false
            })
          }}
        />
      </ThemeProvider>
      <Snackbar 
            open={this.state.message.length != 0}
            autoHideDuration={10 * 1000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
            onClose={e => this.setState({message: ""})}
            >
            <MuiAlert 
              severity="info"
              elevation={6} 
              variant="filled"
              onClose={e => this.setState({message: ""})}>
              {this.state.message}
            </MuiAlert>
               
          </Snackbar>
    </div>
    )
  }
}

function PrintContainer(props) {
  const [fontSize, setFontSize] = React.useState(12);
  const [lineHeight, setLineHeight] = React.useState(14);
  const [paragraphSpace, setParagraphSpace] = React.useState(10);
  const [warning, setWarning] = React.useState("");
  const [ganaVisiable, setGanaVisiable] = React.useState(true);

  const openSnack = warning.length !== 0;
  if(props.isPrintMode) {
    return (
      <div style={props.style}>
        <ThemeProvider theme={theme}>
          <div style={{display:'flex', margin: '16px auto'}}>
            <div>
              <div className="pageContentSetting">
                <div>
                  字體大小：
                  <RemoveIcon onClick={e => {
                    if(fontSize === 12 && window.chrome) {
                      setWarning("Chrome 有最小字體限制，如在意列印品質，請改用Firefox或是Safari瀏覽器。");
                      setTimeout(() => setWarning(""), 5000);
                    }
                    setFontSize(fontSize - 0.5);
                  }}/>
                    <div style={{fontFamily:'monospace', fontSize: '12pt'}}>{fontSize.toFixed(1)}pt</div>
                  <AddIcon onClick={e => setFontSize(fontSize + 0.5)}/>
                </div>
                <div>
                  行高：
                  <RemoveIcon onClick={e => {
                    setLineHeight(lineHeight - 1);
                  }}/>
                    <div style={{fontFamily:'monospace', fontSize: '12pt'}}>{lineHeight}px</div>
                  <AddIcon onClick={e => setLineHeight(lineHeight + 1)}/>
                </div>
                <div style={{flex: '1 1 0'}}/>
                <div onClick={e => setGanaVisiable(!ganaVisiable)}>
                  {ganaVisiable ? <Visibility/> : <VisibilityOff/>}
                </div>
                <div>
                  <PrintIcon onClick={e => window.print()}/>
                </div>
              </div>
              <div className={['content', props.isPrintMode ? 'a4' : '', ganaVisiable ? '' : 'furiganaHidden'].join(' ')}>
                <div style={{fontSize: `${fontSize}pt`}}>
                  <div>{props.paragraphs.map((e,idx) => <div key={idx} className="paragraph" style={{lineHeight: `${lineHeight}px`}}>{e}</div>)}</div>
                </div>
              </div>
            </div>
          </div>
          <Snackbar 
            open={openSnack}
            autoHideDuration={5 * 1000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
            >
            <MuiAlert 
              severity="warning"
              elevation={6} 
              variant="filled"
              onClose={e => setWarning("")}>
              {warning}
            </MuiAlert>
               
          </Snackbar>
        </ThemeProvider>
      </div>
    )
  }
  else {
    return (
      <Container fixed style={props.style}>
        <ThemeProvider theme={theme}>
          <div className={['content']}>
            {props.paragraphs.length !== 0 ? <div style={{fontSize: 8, marginBottom: 8}}>點選單字可以查詢字典</div> : <div></div>}
            <div>{props.paragraphs.map((e,idx) => <div key={idx} className="paragraph">{e}</div>)}</div>
          </div>
        </ThemeProvider>
      </Container>
    )
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const validateOkurigana = (okurigana) => {
  let count = 0
  for(let i = 0; i < okurigana.length; i++) {
    if(okurigana[i] === '('){
      count++;
      if(count !== 1) {
        break;
      }
    } else if(okurigana[i] === ')') {
      count--;
    }
  }
  return count === 0;
}

function FullScreenDialog(props) {
  
  let wordInfo = props.wordInfo;
  // console.log(wordInfo);
  const [okurigana, setOkurigana] = React.useState("");
  const [dictionary, setDictionary] = React.useState("");
  
  if(wordInfo == null) {
    return <div></div>
  }

  let bookmarkIcon = (<BookmarkBorderIcon/>)
  
  if(wordInfo['bookmark']) {
    bookmarkIcon = <BookmarkIcon/>
  }

  return (
    <Dialog
        fullScreen={true}
        open={props.dialogOpen}
        onExited={e => {
          setOkurigana("");
          setDictionary("");
        }}
        TransitionComponent={Transition}
      >
      <AppBar position="relative">
        <Toolbar>
          < IconButton edge="start" color="inherit" onClick={() => props.onClose()}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{flex:1}}>
            {wordInfo['surface']}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={() => props.addBookmark(!wordInfo['bookmark'])} aria-label="close">
            {bookmarkIcon}
          </IconButton>
          <IconButton edge="start" color="inherit" onClick={() => window.open(`https://www.weblio.jp/content/${dictionary ? dictionary : wordInfo['dictionary']}`)} aria-label="close">
            <FindInPageIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container fixed>
        <List component="nav">
          <ListItem> 
            <TextField
              fullWidth={true}
              label="活用"
              disabled
              defaultValue={`${wordInfo['品詞'][0]}, ${wordInfo['活用型'][0]}`}
            />
          </ListItem>
          <ListItem> 
            <TextField
              error={!validateOkurigana(okurigana)}
              helperText={validateOkurigana(okurigana) ? " " : "格式錯誤"}
              fullWidth={true}
              label="送り仮名"
              defaultValue={wordInfo['okurigana']}
              onChange={(e) => setOkurigana(e.target.value)}
              InputProps={{
                endAdornment: 
                  <ReportButton
                    visibility={false && okurigana.length !== 0 && okurigana !== wordInfo['okurigana']}
                    onClick={() => {}}
                  />
              }}
            />
          </ListItem>
          <ListItem> 
            <TextField
              fullWidth={true}
              label="辞書"
              defaultValue={wordInfo['dictionary']}
              onChange={(e) => setDictionary(e.target.value)}
              InputProps={{
                endAdornment: 
                  <ReportButton
                    visibility={false && dictionary.length !== 0 && dictionary !== wordInfo['dictionary']}
                    onClick={() => {}}
                  />
              }}
            />
          </ListItem>
          {/* <ListItem style={{display:'flex', justifyContent:'flex-end'}}>
            <Button color="primary" onClick={() => {
              if(!validateOkurigana(okurigana)) {
                return;
              }
              
              props.onSave(
                okurigana ? okurigana : wordInfo['okurigana'], 
                dictionary ? dictionary : wordInfo['dictionary']
              )
            }}>更新</Button>
          </ListItem> */}
        </List>
      </Container>
    </Dialog>
  );
}

const reportButtonTheme = createMuiTheme({
  palette: {
    secondary: {
      main: orange[500],
    },
  },
});

function ReportButton(props) {
  return <ThemeProvider theme={reportButtonTheme}>
    <InputAdornment position="end">
      {props.visibility ? <Button onClick={props.onClick} color="secondary" startIcon={<InfoIcon />}>回報錯誤</Button> : <div/>}
    </InputAdornment>
  </ThemeProvider>
}