class Utils {
    static isKanji = (c) => {
        let num = c.charCodeAt(0)
        return (num >= 0x4E00 && num <= 0x9FFF) || c === '々'　|| c === 'ヶ'　|| c === 'ヵ'
    }
    static isHiragana = (c) => {
        let num = c.charCodeAt(0)
        return num >= 12353 && num <= 12438
    }
    static isAlpha = (c) => {
        let num = c.charCodeAt(0)
        return num >= 65 && num <= 122
    }
    static isNumber = (c) => {
        let num = c.charCodeAt(0)
        return num >= 48 && num <= 57
    }
    static isPureAlpha = (s) => {
        for(let i = 0; i < s.length; i++){
            if(!Utils.isAlpha(s[i])){
                return false
            }
        }
        return true;
    }

    static isPureHiragana = (s) => {
        for(let i = 0; i < s.length; i++){
            if(!Utils.isHiragana(s[i])){
                return false
            }
        }
        return true;
    }
    
    static isPureKanji = (s) => {
        for(let i = 0; i < s.length; i++){
            if(!Utils.isKanji(s[i])){
                return false
            }
        }
        return true;
    }

    static hasKanji = (s) => {
        for(let i = 0; i < s.length; i++){
            if(Utils.isKanji(s[i])){
                return true
            }
        }
        return false;
    }
}

export default Utils