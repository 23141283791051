
const host = "https://furigana-lychee.herokuapp.com/";
// const host = "http://localhost:8080/"

const fetchRequest = (url, params={}, timeout=10000) => {
    let isTimeout = false;
    return new Promise(function(resolve, reject) {
        const TO = setTimeout(function() {
            isTimeout = true;
            reject(new Error('Fetch timeout'));
        }, timeout);

        fetch(url, params)
            .then(res => {
                clearTimeout(TO)
                if(!isTimeout) {
                    resolve(res)
                }
            }).catch(e => {
                if( isTimeout ){
                    return
                }
                reject(e)
            })
    })
}

class API {
    
    static async checkServerAlive() {
        try {
            await fetchRequest(host, null, 1500);
            return true
        } catch(e) {
            return false
        }
    }

    static async query(str) {
        try {
            const response = await fetch(host, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({text: str}),
            }).then(res => res.json());
            return response;
        } catch(e) {
            return []
        }
    }

    static async report(title, content, payload) {
        const response = await fetch(`${host}/report`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                text: `${title}: \`${content}\`\n\`\`\`${payload}\`\`\``
            }),
        }).then(res => res.text());
        return response;
    }
}

export default API